import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../style/normalize.css'
import '../style/all.scss'

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title='Despre noi'
        keywords={[
          `analog`,
          `fotografie pe film`,
          `film photography`,
          `analogue`,
          `cluj`,
          'cluj-napoca',
        ]}
        image={data.darkroom.childImageSharp.fluid.src}
      />

      <article className='post-content page-template no-image'>
        <div className='post-content-body'>
          <h2 id='clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-'>
            Ne-am săturat să developăm filme în baie, șezând pe capacul de WC.
          </h2>
          <hr />
          <figure className='kg-card kg-image-card kg-width-wide'>
            <Img
              fluid={data.darkroom.childImageSharp.fluid}
              className='kg-image'
            />
            {/* <figcaption>Large imagery is at the heart of this theme</figcaption> */}
          </figure>
          <h3 id='dynamic-styles'>Salutare</h3>
          <p>
            Noi suntem{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/ralea1313'
            >
              Marian
            </a>{' '}
            și{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/luminiu'
            >
              Cristian
            </a>{' '}
            și împreună am făcut{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/grainlab_ro'
            >
              GrainLab
            </a>
            . L-am pornit pentru că ne saturaserăm să developam în baie, la noi
            acasă și ne-am gândit că poate și alții ar vrea să developeze
            manual. Așa s-a născut ideea de a face un laborator în care să putem
            și "mări" fotografii pe hârtie.
          </p>
          <p>
            Deocamdată developăm filme C-41 (color) cu un procesator automat,
            iar pe cele alb-negru manual. Developam și hârtie automat (lumea îi
            zice printăm, dar de fapt e chiar developare, doar că automată) pe
            diferite dimensiuni. Mai multe despre asta în pagina de servicii.
          </p>
          <p>
            Încă nu developăm diapozitive, dar sperăm să începem să facem asta
            în curând.
          </p>
          <p>
            Iar cel mai fain e că organizam workshopuri în care vă învățam să
            developați filme, iar apoi să măriți imaginile pe hârtie, pentru că
            avem o cameră obscură complet echipată profesional ♥♥♥
          </p>
          <hr />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    darkroom: file(relativePath: { eq: "darkroom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360, traceSVG: { color: "#fec245" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
